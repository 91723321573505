export const states = {
  Nigeria: [
    "Abuja(fct)",

    "Abia",

    "Adamawa",

    "Akwa Ibom",

    "Anambra",

    "Bauchi",

    "Bayelsa",

    "Benue",

    "Borno",

    "Cross River",

    "Delta",

    "Ebonyi",

    "Edo",

    "Ekiti",

    "Enugu",

    "Gombe",

    "Imo",

    "Jigawa",

    "Kaduna",

    "Kano",

    "Katsina",

    "Kebbi",

    "Kogi",

    "Kwara",

    "Lagos",

    "Nasarawa",

    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ],
  SouthAfrican: [
    "Eastern Cape",
    "Free State",
    "Gauteng",
    "KwaZulu-Natal",
    "Limpopo",
    "Mpumalanga",
    "Northern Cape",
    "North West",
    "Western Cape",
  ],
};

export const banks = {
  Nigeria: [
    { name: "Access Bank", code: "044" },
    { name: "Zenith Bank", code: "057" },
    { name: "GTBank", code: "057" },
    { name: "First Bank of Nigeria", code: "011" },
    { name: "UBA", code: "022" },
    { name: "Union Bank", code: "032" },
    { name: "Fidelity Bank", code: "070" },
    { name: "FCMB", code: "214" },
    { name: "Enterprise Bank", code: "084" },
    { name: "Citibank Nigeria", code: "000" },
    { name: "Ecobank", code: "050" },
    { name: "Heritage Bank", code: "030" },
    { name: "Keystone Bank", code: "082" },
    { name: "Standbic IBTC", code: "039" },
    { name: "Standard Chartered", code: "000" },
    { name: "Sterling Bank", code: "232" },
    { name: "Unity Bank", code: "215" },
    { name: "Wema Bank", code: "035" },
  ],
  SouthAfrica: [
    { name: "Absa Bank", code: "Absa Bank" },
    { name: "African Bank", code: "African Bank" },
    { name: "Bidvest Bank", code: "Bidvest Bank" },
    { name: "Capitec Bank", code: "Capitec Bank" },
    { name: "Discovery Bank", code: "Discovery Bank" },
    { name: "First National Bank", code: "National Bank" },
    { name: "Investec", code: "Investec" },
    { name: "Nedbank", code: "Nedbank" },
    { name: "Standard Bank", code: "Standard Bank" },
    { name: "Ubank", code: "Ubank" },
    { name: "TymeBank", code: "TymeBank" },
  ],
};

export const pudo = {
  "Eastern Cape": [
    "43 Air School",
    "Addo Brauhaus Paterson",
    "Amalinda Square",
    "Bathurst Butchery",
    "Beacon Bay Retail Park",
    "Bothas Garage",
    "Build It Bushmans",
    "Build It Engcobo",
    "Build It Maclear",
    "Build It Siyaavuma",
    "Caltex Alexandria",
    "Caltex Dewing Service Station",
    "Caltex Ntande",
    "Cest La Vie",
    "Coastal Arcade Kenton On Sea",
    "Die Windmeul Oyster Bay",
    "Engen Malabar",
    "Equionx Mall",
    "Flagstaff Square",
    "Foodzone Patensie",
    "Gently Worn",
    "Gillwell Taxi Retail Park",
    "Gonubie Mall",
    "Greenacres Shopping Centre",
    "Hill Street Supermarket",
    "Hozizon Mini Market",
    "Jeffreys Bay Surf Village",
    "Kidds Beach Retail Centre",
    "Kwikspar Colchester",
    "Madala Hardware",
    "Market Square Grahamstown",
    "Moffett Retail Park",
    "Mount Road Pharmacy",
    "Ngcwangubu Mega Save",
    "Nonesi Mall",
    "Noorsveld Farm",
    "OK Foods Golden Valley",
    "OK Minimark Cuyler Street",
    "OK Minimark Despatch",
    "Oviston General",
    "OVK Aliwal North",
    "OVK Barkly East",
    "OVK Burgersdorp",
    "OVK Cradock",
    "OVK Elliot",
    "OVK Hofmeyer",
    "OVK Jamestown",
    "OVK Lady Grey",
    "OVK Middleburg EC",
    "OVK Molteno",
    "OVK Steynsburg",
    "OVK Tarkastad",
    "OVK Willowmore",
    "Ozzies General Store",
    "Paradise Supermarket",
    "Pearston Post office",
    "Pick n Pay 4th Avenue",
    "Rosehill Mall",
    "Royal Joubertina",
    "Royal Krakeel",
    "Royal Louterwater",
    "Royal Misgund",
    "S&J Enterprises",
    "Sasol Abbotsford",
    "Sasol Cape Road",
    "Sasol Chiselhurst",
    "Sasol Fig Tree",
    "Sasol Mdantsane",
    "Sasol Motherwell",
    "Sasol Stanford Road",
    "Sasol Summerstrand",
    "Sasol Volkstud Motors",
    "Savenor Mooiplaas",
    "Saverite Willowmore",
    "SB Motors Somerest East",
    "Settlers Square",
    "Sibuyile Home and Hardware",
    "Spar Bedford",
    "Spar Cala",
    "Spar Cradock",
    "Spar Dagbreek",
    "Spar Indwe",
    "Spar Lady Frere",
    "Spar Ngqamakhwe",
    "Spar Swartkops",
    "Spar Ugie",
    "Spargs Spar Qumbu",
    "Spargs Superspar Butterworth",
    "Spargs Superspar Dutywa",
    "Spargs Superspar Libode",
    "Sterkspruit Plaza",
    "Strand Supermarket",
    "Summerstrand Village Shopping Centre",
    "Superspar Spargs Sprigg",
    "The Courier Guy Alice",
    "The Courier Guy Aliwal North",
    "The Courier Guy Arcadia",
    "The Courier Guy East London",
    "The Courier Guy East London Depot",
    "The Courier Guy Fort Beaufort",
    "The Courier Guy Gonubie",
    "The Courier Guy Graaff Reinet",
    "The Courier Guy Grahamstown",
    "The Courier Guy Jeffreys Bay",
    "The Courier Guy",
    "Middleburg (Eastern Cape)",
    "The Courier Guy Mthatha",
    "The Courier Guy Port Alfred",
    "The Courier Guy port Elizabeth",
    "The Courier Guy Port Elizabeth Kiosk",
    "The Courier Guy Queenstown",
    "The Courier Guy Stutterheim",
    "The Courier Guy Walmer",
    "The Mall King Williams Town",
    "The Village Inn",
    "Total Expressmart Kareedouw",
    "TWK Cedarville",
    "Uncle Freddies",
    "Universal Garage Dordrecht",
    "Vroutjie Se Koutjie",
    "Walker Drive Shopping Centre",
    "Ziyabuya Shopping Centr",
  ],
  "Free State": [
    "AECI Plant Health",
    "Allanridge Engineering",
    "Bloem Plaza",
    "Boxer Welkom",
    "BP Edenburg",
    "Build It Bethlehem",
    "Cooper Penny",
    "Craibs Kontantwinkel",
    "DA Motors Boshof",
    "De Nice Buthchery Deneysville",
    "Dihlabeng Mall",
    "Edenville Quest",
    "Elegant Fuel Thaba Nchu",
    "Elegant Fuel Welkom",
    "Ellas Algemene Handelaar",
    "Engen Truck Stop",
    "Gardenia Square",
    "Heilbron herald",
    "Hinterland Fuels Theunissen",
    "Horeb Supermarket & Butchery",
    "Jostas Corner Koppies",
    "Kairuz Midas",
    "Kgotso Build 4ways",
    "Kgotso Build Botshabelo",
    "Liplekker Supermark",
    "Maluti Superspar",
    "Mega Park Bloemfontein",
    "Middestad Mall",
    "OK Costas Supermarket",
    "OK Express Harrismith",
    "OK Foods Clocolan",
    "Foods Frankfort",
    "OK Minimark Gariep",
    "OVK Bethulie",
    "OVK Brandfort",
    "OVK Clocolan",
    "OVK Dewetsdorp",
    "OVK Fauresmith",
    "OVK Fouriesburg",
    "OVK Hobhouse",
    "OVK Ladybrand",
    "OVK Paul Roux",
    "OVK Reddersburg",
    "OVK Riet Rivier",
    "OVK Rosendal",
    "OVK Rouxville",
    "OVK Smithfield",
    "OVK Trompsburg",
    "OVK Tweespruit",
    "OVK Ventersburg",
    "OVK verteerdevlei",
    "OVK Zastron",
    "Petrusburg Vleismark",
    "Phutaditjhaba Maluti Cresent",
    "Pick n Pay Hoopstad",
    "Pick n Pay Odendaalsrus",
    "Pienk Huis Deli",
    "Puma Warden",
    "Riemland Country Stop",
    "Sakkies Motors",
    "Sasol Mapikela",
    "Sasol One RCC",
    "Sasol Parys",
    "Sasol Power Road",
    "Sasol Vaalpark",
    "Sasol Villiers",
    "Saverite Villiers",
    "Shell Senekal",
    "Shoprite Virginia (Orange Free State)",
    "Spar Parys",
    "Spar Reitz",
    "Spar Vrede",
    "Spar Wesselsbron",
    "The Courier Guy Bethlehem",
    "The Courier Guy Bloemfontein",
    "The Courier Guy Bloemfontein Depot",
    "The Courier Guy Bloemfontein Langenhoven Park",
    "The Courier Guy Bloemfontein Preller Mall",
    "The Courier Guy Botshabelo",
    "The Courier Guy Ficksburg",
    "The Courier Guy Frankfort",
    "The Courier Guy Harrismith",
    "The Courier Guy Kroonstad",
    "The Courier Guy Ladybrand",
    "The Courier Guy Sasolburg",
    "The Courier Guy Virginia (Orange Free State)",
    "The Courier Guy Welkom",
    "TNT Tool Hire",
    "Value Mart Bloemfontein",
    "Vredefort Meubels",
    "Waterbron Cash Store",
    "Winburg Mecca",
    "Windsor Slaghui",
  ],
  Gauteng: [
    "68 On Hobart",
    "Adventure Zone Cullinan",
    "Aero Centre",
    "Alberton City",
    "Alberton Crossing",
    "Alex Mall",
    "ANB Stationers",
    "Aqua Park Shopping Centre",
    "Atlas Mall",
    "Atlas Road Banking Centre Boksburg",
    "Atteridge Plaza",
    "Banbury Cross Village",
    "Batho Plaza",
    "Bedworth Centre",
    "Bella Ombre Shopping Centre",
    "Birchleigh Noth Mall",
    "Blu Valley Mall",
    "Blueberry Square",
    "Boskruin Village Shopping Centre",
    "Boxer Alexandra",
    "BP M2 Motorway & Pick n Pay Express",
    "Brackenhurst Square",
    "Brand Filling Station",
    "Brentwood Park Mall",
    "Broadacres Shopping Centre",
    "Brooklyn Mall",
    "Build It Marapyane ",
    "Caltex Cedar Road Freshstop",
    "Caltex Centre Court",
    "Caltex Redhill freshstop",
    "Caltex Wapadrand Freshstop",
    "Cambridge Crossing",
    "Carletonville Mall",
    "Carletonville Shopping Centre",
    "Carnival Centre",
    "Celtis Ridge Shopping Centre",
    "Central Square",
    "Centurion Golf Estate (Residents Only)",
    "Checkers Mayville",
    "Chilli Lane",
    "Chris Hani Crossing",
    "Clubview Corner Shopping Centre",
    "Co. Space Midrand",
    "Coachmans Crossing",
    "Comaro Crossing",
    "Cornwall View Shopping Centre",
    "Cosmo Business Park",
    "Cosmo Mall",
    "Cradlestone Mall",
    "Cresta Crossing",
    "Crowthorne Shopping Centre",
    "Dainfern Estate (Residents Only)",
    "Decathlon Alberton",
    "Decathlon Centurion",
    "Decathlon Roodeport",
    "Design Quarter",
    "Dobsonville Mall",
    "Eagle Canyon Golf Estate (Residents Only)",
    "East Point Shopping Centre",
    "Echo Fuel Station",
    "Eden terrace Shopping Centre",
    "Electron Excgange",
    "Elgin mall",
    "Elohim Skryfbehoeftes",
    "Engen 99 Krugersdorp West",
    "Engen Filling Station Azaadville",
    "Ferndale On Republic",
    "Festival Mall",
    "Fin Forum",
    "Firestation Rosebank",
    "Flora Shopping Centre",
    "FNB Bank City",
    "FNB Fairlands",
    "Forest Road Design and Décor",
    "Fourways Crossing",
    "Gezina Galleries",
    "Glen Acres Shopping Centre",
    "Glen Balad Mall",
    "Glenhavie Shell Garage",
    "Gouda Slagtery",
    "Grayston Shopping Centre",
    "Greenstone Shopping Centre",
    "Harding Corner Shopping Centre",
    "Heathway Centre",
    "Highway Vaal Filling Station",
    "Hillfox Value Centre",
    "Inospace-Wynberg Works",
    "Jackal Creek Golf Estate",
    "K90 Centre",
    "Kaalfontein Centre",
    "Kempton Gate Mall",
    "Kempton Shoprite Centre",
    "Killarney Mall",
    "Kolonnade Retail Park",
    "Kopanong Kudube Shopping Centre",
    "Kotwal Motor Spares",
    "KwaMashu Shopping Centre",
    "Kyalami On Main Shopping Centre",
    "L Corro Centre Northcliff",
    "Lavender Square",
    "Mabopene Square",
    "Magalies Village Square",
    "Mall @The Junction",
    "Mall of the South",
    "Mayfield Square Shopping Centre",
    "MBT Filling Station Garsfontein",
    "Meadowdale Mall",
    "Meadowpoint Shopping Centre",
    "Menlyn park Shopping Centre",
    "Midway Mews",
    "Montana Crossing Centre",
    "Morning Glen mall",
    "Morningside Shopping Centre",
    "Nedbank Avo-1",
    "Nedbank Avo-2",
    "North Park Mall",
    "Northlands Corner",
    "Northmead Square",
    "Ntuli Shopping Square",
    "Obarp Tijgervallei",
    "October Avenue Ivory Park",
    "OK Foods Sunnyridge",
    "OK Foods Wadrif",
    "OK Minimark Mossel Bay",
    "Okgonne Filling Station",
    "Olievenhout Plaza",
    "Orange Farm Town Square",
    "Palm court",
    "Park Meadows Mall",
    "Parktown Quarter",
    "Philippolis Hotel",
    "Pick n Pay Centre Sam Sekoti",
    "Piet Els Motors",
    "Pimville Square",
    "Protea point Shopping Centre",
    "Puma Drift",
    "Puma Kabot",
    "Puma Murrayhill",
    "Randburg Square",
    "Randfontein Station Mall",
    "Randridge Mall",
    "Randview Shopping Centre",
    "Raslouw Lifestyle Centre",
    "Redruth Village",
    "Retail Crossing West Rand",
    "River Walk Shopping Centre",
    "Ruimsig Shopping Centre",
    "Rynfield Terrace Shopping Centre",
    "Sam Ntuli Mall",
    "Sannieshof Wholesalers",
    "Sasol Andrew Mapheto",
    "Sasol Bowling Avenue",
    "Sasol Circle Centre",
    "Sasol Cloverdene",
    "Sasol Dalpark",
    "Sasol Davest",
    "Sasol Derdeport",
    "Sasol Diepslot",
    "Sasol Dorandia",
    "Sasol Endenvale",
    "Sasol Excellence",
    "Sasol F & S Nigel",
    "Sasol Hatfield",
    "Sasol Hendrik Potgieter",
    "Sasol Hennopspark",
    "Sasol Hill Street",
    "Sasol Hornsnek",
    "Sasol Houghton",
    "Sasol Jean Avenue",
    "Sasol Kingsview",
    "Sasol Kingsway",
    "Sasol Lynnwood Ridge",
    "Sasol Lyttleton Manor",
    "Sasol Main Reef",
    "Sasol Malanshof",
    "Sasol Mamelodi",
    "Sasol Midrand",
    "Sasol Midridge",
    "Sasol Newmarket",
    "Sasol Nina Park",
    "Sasol Noordheuwel",
    "Sasol North Riding",
    "Sasol Northmead",
    "Sasol Olivedale",
    "Sasol Parkdene",
    "Sasol Philip Nel Park",
    "Sasol Pinehaven",
    "Sasol Ravenwood East",
    "Sasol Ravenwood West",
    "Sasol Rietfontein ",
    "Sasol Risiville",
    "Sasol Roodekruin",
    "Sasol Schurveberg",
    "Sasol Sedibeng",
    "Sasol Skinner Street",
    "Sasol SSS Vanderbijlpark",
    "Sasol St Bernard",
    "Sasol Stormvoel",
    "Sasol Suikerbos Motors",
    "Sasol Thembisa",
    "Sasol Theresa Park",
    "Sasol Van Burren",
    "Sasol Vanderbijlpark",
    "Sasol Vereeniging",
    "Sasol Voortrekker Road",
    "Sasol West Star",
    "Sasol West Village",
    "Sasol Zambezi South",
    "Sebokeng Plaza",
    "Serengeti Golf Estate (Residents Only)",
    "Shell Moreleta Park",
    "Shell Sundowner Motors",
    "Shoprite Centre Marikana",
    "Soshanguve Crossing Mall",
    "Southview Shopping Centre",
    "Soweto Power Park",
    "Spar Boksburg",
    "Springs Mall",
    "Springsgate Shopping Centre",
    "Stonehill Crossing",
    "Strubenvale Pharmacy",
    "Struisbaai Hengel Winkel",
    "Sunagte Farms",
    "Sunninghill Square",
    "Supa Quick Armadale",
    "Supa Quick Germiston ",
    "Supa Quick Lenasia",
    "Talent Demo 1",
    "Talent Test2",
    "The Conservatory @Lakefield",
    "The Courier Guy Alberton",
    "The Courier Guy Bedfordview",
    "The Courier Guy Boksburg",
    "The Courier Guy Broadacres",
    "The Courier Guy Bryanston",
    "The Courier Guy Cape Town Kiosk",
    "The Courier Guy Carletonville",
    "The Courier Guy Cloverdene",
    "The Courier Guy Craighall Park",
    "The Courier Guy Crown Mines",
    "The Courier Guy Dainfern",
    "The Courier Guy Equestria",
    "The Courier Guy Hatfield",
    "The Courier Guy Hennopspark",
    "The Courier Guy Jewel City",
    "The Courier Guy Johannesburg kiosk",
    "The Courier Guy Krugersdorp",
    "The Courier Guy Louis Trichardt",
    "The Courier Guy Midrand",
    "The Courier Guy Northmead",
    "The Courier Guy Parkhurst",
    "The Courier Guy Parys",
    "The Courier Guy Randfontein",
    "The Courier Guy Rivonia",
    "The Courier Guy Silverton",
    "The Courier Guy Springs",
    "The Courier Guy Strydompark",
    "The Courier Guy Vanderbijlpark",
    "The Courier Guy Vareenigning",
    "The Courier Guy Waltevredenpark",
    "The Marc",
    "The Ridge Shopping Centre",
    "The Square",
    "The Village Mall",
    "The Wall Centre Ballito",
    "Thorntree Shopping Centre",
    "To Dye For",
    "Total De Deur",
    "Town Square Mall",
    "Tsakane Corner",
    "Tsakane Mall",
    "TWK Ixopo",
    "TWK Lothair",
    "Verdi Shopping Centre",
    "Victory Park Shopping Centre",
    "Viva CJ Mahomed",
    "VR Mall Edenvale",
    "Warranty House",
    "Waterglen Shopping Centre",
    "Westwood Village",
    "Willow Way Shopping Centre",
    "Willowrock Value Centre",
    "Willows Crossing Centre",
    "Wilrogate Shopping Centre",
    "Wonderpark Shopping Centre",
    "Woodmead Retail Park",
    "Woodmead Super Value Mal",
  ],
  "KwaZulu-Natal": [
    "Aheers Foodworks",
    "Aheers Supermarket",
    "Belele Motors",
    "Berea Centre",
    "Bluff Towers",
    "BP Quarry",
    "Build Hlabisa",
    "Build It Estcourt",
    "Caltex Cato Ridge Freshstop",
    "Caltex Pongola",
    "Caltex Thongasi Freshstop",
    "Caltex Umtentweni",
    "Caltex Winston Park Freshstop",
    "Cedarwood Village Shopping Centre",
    "Checkstar Durban",
    "Checkstar Mount Edgecombe",
    "Checkstar Overport",
    "Colenso Motors",
    "Davenport Square",
    "Delcairn Lifestyle Centre",
    "Die Opstal Memel",
    "Emprint Empangeni",
    "Engen Kenfield",
    "Fairways On Main",
    "Gillitts Corner",
    "Gremlins Margate",
    "Hammarsdale Junction Mall",
    "Heyfields Mall",
    "Hibberdene Super Spar",
    "Hillary Service Station",
    "Jeffs Service Station",
    "Jozini Mall",
    "Kensington Boulevard ",
    "Kozi Café",
    "Lennoxton Service Station",
    "Lusiki Plaza",
    "Mandeni Mall",
    "Matatiele Service Station",
    "Mays Auto Centre",
    "Meerensee Filling Station",
    "Mica Paulpietersburg",
    "Montclair Mall",
    "Mount Edgecombe estate (Residents Only)",
    "Mpophomeni Mall",
    "Musgrave Centre",
    "New Hanover Garage",
    "Newcastle Corner",
    "Nquthu Plaza",
    "OK Express Puma Dalton",
    "Park Boulevard",
    "Phoenix Plaza",
    "Pine Crest Shopping Centre",
    "Post Script Pennington",
    "Protea Hardware Dannhauser",
    "Qualbert Centre",
    "Roservior Hills Shopping Centre",
    "Richmond Shopping Centre",
    "Sasol Bellair",
    "Sasol Maathula",
    "Sasol Merrivale",
    "Sasol NMR",
    "Sasol Port Shepstone",
    "Sasol Salt Rock",
    "Sasol Umgeni",
    "Scottburgh Mall",
    "Scottburgh Motors",
    "Seadoone service Station",
    "South Coast Mall",
    "Southport Swop Shop",
    "Spar 1000 Hills ",
    "Spar Ladysmith",
    "Spar Louwsburg",
    "Spar Mapumulo",
    "Spar Mtunzini",
    "Spar Renckens",
    "Spar Umkomaas",
    "Spar Winterton",
    "Spares City Ulundi",
    "Spargs Supermarket Kokstad",
    "Spargs Superspar Mount Frere",
    "Supa Quick Bergville",
    "Supa Quick Camperdown",
    "Superkhulu Hardware",
    "The Atrium Berea",
    "The Atrium Eshowe",
    "The Courier Guy Ballito",
    "The Courier Guy Dundee",
    "The Courier Guy Empangeni",
    "The Courier Guy Estcourt",
    "The Courier Guy Hillcrest",
    "The Courier Guy Howick",
    "The Courier Guy Kokstad",
    "The Courier Guy Ladysmith",
    "The Courier Guy Manguzi",
    "The Courier Guy Mtubatuba",
    "The Courier Guy Newcastle",
    "The Courier Guy Pietermaritzburg",
    "The Courier Guy Pongola",
    "The Courier Guy Port Edward",
    "The Courier Guy Port Shepstone",
    "The Courier Guy Richards Bay",
    "The Courier Guy Stanger",
    "The Courier Guy Underberg",
    "The Courier Guy Vryheid",
    "Theku Plaza",
    "Tiffany’s Shopping Centre",
    "Total Ashburton",
    "Total Southport",
    "Tugela Ferry Mall",
    "Tugela Truck Inn",
    "TWK Mooi River",
    "TWK Swartberg",
    "TWK Underberg",
    "Umlazi Mega City",
    "Umzimkhulu Mall",
    "Weenen Agri",
    "Westville Junction",
    "Westwood Mall",
    "Zinkwazi Beach Café",
  ],
  Limpopo: [
    "7 Eleven Polokwane",
    "AI 3 Plaasmark",
    "Algemeen Handelaar Maasstroom",
    "Bela Mall",
    "Blouberg Mall",
    "Boabab Wholesaler",
    "Bougainvilla Country Store",
    "Boxer Centre Tzaneen",
    "Build It Maake",
    "Build It Modjadji",
    "Build It Namakgale",
    "Build It Nkwowankowa",
    "Build It Nokaweng",
    "Build It Sekororo",
    "Build It Selala",
    "Bulgerivier Handelaar",
    "Caltex CLB Garage",
    "Cashbuild Seshego",
    "Circle Centre",
    "Driehoek Filling Station",
    "Elegant Fuel Levubu",
    "Enco Filling Station",
    "Game Groblersdal",
    "Goodluck Supermarket",
    "Gravelotte Butchery",
    "Hubyeni Shopping Centre",
    "Jane Furse Plaza",
    "Lephalale Mall",
    "Lorein Supermarket Dwaalboom",
    "Maake Plaza",
    "Mahlakung Plaza",
    "Makhado Air Force Base",
    "Mala Plaza",
    "Mall of the North",
    "Marapong Shopping Centre",
    "Marula Mall ",
    "Masingita Plaza",
    "Modi Mall",
    "Mooketsi Hardware",
    "Moratiwa Crossing",
    "Musina Mall",
    "Nzelele Valley Shopping Centre",
    "Obaro Koedoeskop",
    "Obaro Naboomspruit",
    "Obaro Northam",
    "Obaro Potgietersrus",
    "OK Minimark gigi",
    "OK Minimark Letsitele",
    "OK Minimark Tarentaalrand",
    "Olifant Oasis",
    "Polokwane Cycad Centre",
    "Rooiberg One Stop",
    "Saselamani NKuna Complex",
    "Sasol Peinna Park",
    "Sasol Steelpoort",
    "Sasol Tzaneen",
    "Savemor Diphagane ",
    "Shoprite Mankweng",
    "Sign Centre",
    "Spar Lulekani",
    "Steenbok Handelaars",
    "Supa Quick Polokwane",
    "Superspar plaza Mokopane",
    "Sydney Halt",
    "Thaba Mall",
    "Thavhani Mall",
    "The Courier Guy Bela-Bela",
    "The Courier Guy Groblersdal",
    "The Courier Guy Hoedspruit",
    "The Courier Guy Lephalale",
    "The Courier Guy Modimolle",
    "The Courier Guy Musina",
    "The Courier Guy Phalaborwa",
    "The Courier Guy Polokwane",
    "The Courier Guy Thabazimbi",
    "The Courier Guy Thohoyandou",
    "The Courier Guy Tzaneen",
    "The Eatery on Rissik",
    "Total Masorini Phalaborwa",
    "Tshilamba Retail Centre",
    "Viva Bakenburg",
    "Viva Bandelierkop",
    "Viva Boyne",
    "Viva Dzanani",
    "Viva Marbel Hall",
    "Viva Marholeni",
    "Viva Marken",
    "Viva Ndlwana",
    "Viva Roedtan",
    "Viva Samey",
    "Viva Sibasa",
    "Vivo Savemor",
    "Waterpoort Winke",
  ],
  Mpumalanga: [
    "Amersfoort Filling Station",
    "Blinkwater Vulstasie",
    "Bosbok Apteek",
    "Breyten Hardware",
    "Build It Ayobs",
    "Build It Driefontein",
    "Build It Praktiseer",
    "Build It Tubatse",
    "Caltex Andrew motors",
    "Caltex Bushbuckridge",
    "Caltex Carolina",
    "Caltex Doornkraal",
    "Caltex Forever Resort Badplaas",
    "Caltex Pullenshope",
    "Caltex Superstop Sabie",
    "Caltex White River",
    "Chothia & Corp",
    "Cosmo Centre",
    "Crossing Centre Nelspruit",
    "Crossroads Plaza",
    "Del Judor Mall",
    "Dendars Hardware",
    "Empoyeni Mall",
    "Game Ermelo",
    "Impala Motors",
    "Jumbo Furniture",
    "Kabokweni Plaza",
    "KG Mall",
    "Kwagga Plaza",
    "Londolozi Filling Station",
    "M and J Bottlestore",
    "Malelane Choppies Centre",
    "Marlothi Shopping Centre",
    "Mascor Komatipoort",
    "Masterhoster",
    "MBT Charl Cilliers",
    "MBT Dullstroom",
    "Mica Volksrut",
    "Middleburg Mall",
    "Moollas Hardware",
    "Msogwaba Motors",
    "National Park Butchery",
    "Nelspruit Plaza",
    "Obara Lydenburg",
    "OK Express Machadodorp",
    "OK Foods Middleburg",
    "Orchards N4 Service Station",
    "Pack n Pay Supermarket",
    "Perfect Water Kriel",
    "Phola Mall",
    "Sasol Delmas",
    "Sasol Evander",
    "Sasol Flora Park",
    "Sasol Secunda CBD",
    "Sasol Secunda Junxion",
    "Sasol Sheritas",
    "Sasol Swartbos",
    "Sasol Watermeyer",
    "Sasol West End",
    "Savemor Platsak",
    "Sheba Motors",
    "Spar Belfast",
    "Spar Carolina",
    "Sasol Graskop",
    "Spar Savemor Arnot",
    "Starstop Machado",
    "Steyns Motors",
    "Task Motors Barberton",
    "The Courier Guy Barberton",
    "The Courier Guy Bethal",
    "The Courier Guy Burgersfort",
    "The Courier Guy Ermelo",
    "The Courier Guy Komatipoort",
    "The Courier Guy Lydenburg",
    "The Courier Guy Malelane",
    "The Courier Guy Middelburg",
    "The Courier Guy Nelspruit",
    "The Courier Guy Piet Retief",
    "The Courier Guy Secunda",
    "The Courier Guy Standerton",
    "The Courier Guy Volksrust",
    "The Courier Guy Witbank",
    "Tops Ogies",
    "Village Shopping Centre",
    "Viva Big 5 Nelspruit",
    "White River Crossing",
    "White River Squar",
  ],
  "Northern Cape": [
    "Alstop Supermarket",
    "Auto Tyre Barkly West",
    "Carnarvon Supermarket",
    "Die Bondeltjie",
    "Dop en Kreef",
    "Grootdrink Winkel & Drankwinkel",
    "Hoofweg Motors",
    "KBD Engen Fuel Station",
    "Kenhardt Fuel Station",
    "Keurstop Diensstasie",
    "Kim Park Shopping Centre",
    "Klein Oqis",
    "Kuruman Mall",
    "KVB Vosburg",
    "Leka Liqours",
    "Lime Acres Slaghuis",
    "Loxton Lekker",
    "Marydale Garage",
    "Mollies Vulstasie",
    "Mouers Enterprise",
    "N12 Total Strydenburg",
    "Oasis Café Noupoort",
    "Obaro Hartswater",
    "OK Foods Calvinia",
    "OK Foods Garies",
    "OK Foods Rednic",
    "OK Foods Sutherland",
    "OK Grocer Black Rock",
    "OK Grocer Hopetown",
    "OK Grocer Hotazel",
    "OK Minimark Olifantshoek",
    "OK Minimark Towers",
    "Ons Apteek Aggeneys",
    "Onse Winkel Baken",
    "OVK Colesburg",
    "OVK Hopetown",
    "OVK Petrusville",
    "OVK Prieska",
    "Pitstop Biltong & Tuckshop",
    "Pofadder Auto",
    "Print @Victoria West",
    "Redira Clothing Augrabies",
    "Redira Clothing Upington",
    "Roux Drankwinkel",
    "Sasol Tsantsabane",
    "Savemor Hanover",
    "Savemor Nieuwoudtville",
    "Saverite Douglas",
    "Saverite Kakamas",
    "Saverite Keimoes",
    "Saverite Stella",
    "Saverite Warrenton",
    "Spar Danielskuil",
    "Spar Loeriesfontein",
    "Spar Port Nolloth",
    "Spar Prieska",
    "The Courier Guy Colesburg",
    "The Courier Guy De Aar",
    "The Courier Guy Douglas",
    "The Courier Guy Hopetown",
    "The Courier Guy Kathu",
    "The Courier Guy Kimberley",
    "The Courier Guy Kimberley 2",
    "The Courier Guy Kuruman",
    "The Courier Guy Prieska",
    "The Courier Guy Springbok",
    "The Courier Guy Upington",
    "Total Fraserburg",
    "Vaalbos Hardware",
    "VDK Two Way Radios ",
    "Viva Kagung",
    "Werda Supermark Phillipstown",
    "Williston Vleiskooperasi",
  ],
  "North West": [
    "Boitekong Mall",
    "Boshoek Supermarket",
    "Caltex Tuang",
    "Damdoryn Pharmacy",
    "Derby Fuel and Truck",
    "Emslies Engen",
    "Exel-Mams Truck Inn",
    "Flamwood Walk",
    "Foodzone Rietfontein",
    "Gopane Filling Station",
    "Kanana Mall",
    "Louwna Supermark",
    "Magalies View",
    "MBT N14 Ventersdorp",
    "Mogwase Hardware",
    "Obaro Brits",
    "Obaro Potchefstroom",
    "Obaro Schuinsdrift",
    "OK Foods Coligny",
    "OK Foods La Hoff",
    "Ottosdal Pharmacy",
    "Panorama Kwikspar",
    "Puma Bothaville",
    "Puma Bultfontein",
    "Puma Ganyesa",
    "Puma Watsonia",
    "Quattro Fuels",
    "Reivilo Petroleum",
    "Rietvlei Melkery & Slaghuis",
    "Rousseau Vulstasie",
    "Rustenburg Mall",
    "Sasol Aerodrome",
    "Sasol Baillie Park",
    "Sasol Brits 1",
    "Sasol Broederstroom",
    "Sasol Goudkop",
    "Sasol Inn",
    "Sasol Mafikeng",
    "Sasol Protea Park",
    "Sasol Riviera Park",
    "Saverite Hartbessfontein",
    "Scott Crescent Spar",
    "Spar Delareyville",
    "Stellaronas Carwash",
    "Subway Motors",
    "The Courier Guy Bloemhof",
    "The Courier Guy Klerksdorp",
    "The Courier Guy Lichtenburg",
    "The Courier Guy Mafikeng",
    "The Courier Guy Potchefstroom",
    "The Courier Guy Rustenburg",
    "The Courier GuyVryburg",
    "The Courier Guy Zeerust",
    "Total Hekpoort",
    "Total Meiringspark",
    "Total Orkney",
    "Total Skeerpoort",
    "Tower Mall",
    "Truck Inn Potchefstroom",
    "Vaalkop Tuckshop",
    "Viva Slurry",
    "Vrugte uit die Boord",
    "Waterfall Mall",
    "Zeerust Shopping Centr",
  ],
  "Western Cape": [
    "30 Voortrekker Road",
    "ABC Boekwinkel",
    "Ashton Platform 62",
    "ATKV Hartenbos Standoord",
    "Atlantis Office Park",
    "Avanti office Park",
    "Bayview Centre",
    "Baywest Mall",
    "Caltex Dana Bay",
    "Car Motors Clanwilliam",
    "Central Ceres Motors ",
    "Central Garage Gansbaai",
    "Cycleworx Sedgefield ",
    "De Kraal Slaghuis Brandvlei",
    "Drummong Arms Restaurant ",
    "Eden on the Bay",
    "Emporium Centre",
    "Evita se Perron",
    "Fish Hoek Town Square",
    "Garden Route Meat Market",
    "Gardens Shopping Centre",
    "Gugulethu Square",
    "HD Ruiters Padkafee",
    "Inospace – Epping Works",
    "Inospace – Powder Mill",
    "Inospace - Textile Exchange",
    "Inospace – The Island",
    "Inospace – Wetton Works",
    "Knysna Mall",
    "Kouestroom Bouers",
    "KVB Murraysburg",
    "La Belle Q4 Stikland",
    "Laborie Centre",
    "Lamberts Bay Signs and Printing",
    "Langemeer Service Station",
    "Lansdowne Corner",
    "Macassar Shopping Centre",
    "Maynard Mall",
    "Mcgregor Drankwinkel",
    "Megasave Lutzville",
    "Meltz Centre",
    "Merweville Mall",
    "Midway Centre",
    "Midway Express Breede Rivier",
    "Moov Riviersonderend Country Store",
    "N1 City Mall",
    "Namaqua Garage Vanryhnsdorp",
    "Neelsie Student Centre",
    "Nella se Winkel",
    "Noble Park Shopping Centre",
    "Nuveld Motors",
    "Nyanga Junction",
    "OK Foods Laingsburg",
    "OK Foods Velddrif",
    "OK Foods Villiersdorp",
    "OK Greyton",
    "OK Grocer Barrydale",
    "OK Minimark Ceres",
    "OK Minimark Mowbray",
    "OK Minipark Napier",
    "OK Minimark Onrus",
    "OK Minimark Strandfontein",
    "OK Minimark Wolseley",
    "On Darwin Shopping Centre",
    "Ottery Value Centre",
    "Oudtshoorn Ford",
    "Palm Tyres",
    "Pearly Beach Shop",
    "Pelican Park Shopping Centre",
    "Piazza da Luz",
    "Pick n Pay Riebeeck Wes",
    "Pinehurst Shopping Centre",
    "Piquetberg Mall",
    "Pollocks Pizza Café",
    "Pringle Bay Mini Market",
    "Print @Groot Brakrivier",
    "Q Square Shopping Centre",
    "Rambrandt Mall",
    "Royal Herold",
    "Royal Uniondale",
    "Sasol Buffelsfontein",
    "Sasol De Bron",
    "Sasol Erica Road",
    "Sasol George Highway",
    "Sasol Kleinmond",
    "Sasol Melkbosstrand",
    "Sasol Parklands",
    "Sasol Roelf & Jacks",
    "Sasol Somerset West",
    "Sentra De Rust",
    "Shell Albertinia",
    "Shell Boulevard",
    "Shell Steenberg",
    "Shell Suiderhof Motors",
    "Silo Exchange",
    "Spar Belmont",
    "Spar Bonnievale",
    "Spar Calitzdorp",
    "Spar de Doorns",
    "Spar Hopefield",
    "Spar Ladismith",
    "Spar Langebaan",
    "Spar Moorreesburg",
    "Spar Porterville",
    "Spar Prince Albert",
    "Spar Saldanha",
    "Spar St Helena Bay",
    "Spar Yzerfontein",
    "Stanford General Dealer",
    "Stellenbosch Square",
    "Supa Quick Caledon",
    "TCG Cape Town Depot",
    "The Cabin Restaurant & Pub",
    "The Courier Guy Beaufort West",
    "The Courier Guy Bellville",
    "The Courier Guy Brackenfell",
    "The Courier Guy Bredasdorp",
    "The Courier Guy Ceres",
    "The Courier Guy Durbanville",
    "The Courier Guy Fish Hoek",
    "The Courier Guy Franschhoek",
    "The Courier Guy George",
    "The Courier Guy Grabouw",
    "The Courier Guy Heidelberg (Cape)",
    "The Courier Guy Hermanus",
    "The Courier Guy Hout Bay",
    "The Courier Guy Khayelitsha",
    "The Courier Guy Knysna",
    "The Courier Guy Malmesbury",
    "The Courier Guy Mokopane",
    "The Courier Guy Montague Gardens",
    "The Courier Guy Mossel Bay",
    "The Courier Guy Muizenberg",
    "The Courier Guy Ottery",
    "The Courier Guy Oudtshoom",
    "The Courier Guy Paarl",
    "The Courier Guy Parklands",
    "The Courier Guy Piketberg",
    "The Courier Guy Robertson",
    "The Courier Guy Somerset West",
    "The Courier Guy Steinhoff Business Park",
    "The Courier Guy Stellenbosch",
    "The Courier Guy Still Bay",
    "The Courier Guy Swellendam",
    "The Courier Guy Vredenburg",
    "The Courier Guy Vredendal",
    "The Courier Guy Wellington",
    "The Courier Guy Worcester",
    "The Crags Superette",
    "The Deli Rawsonville",
    "The Orchard Farm Stall Grabouw",
    "The Paddocks Shopping Centre",
    "The Pavilion Shopping Centre",
    "The Route 62 Stop",
    "Tulbagh Garage ",
    "Tygerberg Campus",
    "Village Centre Bettys Bay",
    "Viva Elukwatini",
    "Voorbaai Truckport ",
    "Vredenburg Auto",
    "Waves Garage Convenience Store",
    "Werners Meat Market",
    "Weskus Mall",
    "Westgate Mall",
    "Whale Coast Mall",
    "Wilderness Motors",
    "Woodmill Lane Shopping centre",
  ],
};

export const postnet = {
  "Eastern Cape": [
    "Beacon Bay	",
    "Cradock	",
    "Crossways ELS	",
    "Gonubie	",
    "Graaff	",
    "Grahamstown	",
    "Greenacres	",
    "Hunters Retreat	",
    "Jeffreys Bay	",
    "King Williams Town	",
    "Metlife Plaza	",
    "Moffett on Main	",
    "PE Waterfront	",
    "Port Alfred	",
    "Queenstown	",
    "Savoy Mthatha	",
    "Southernwood	",
    "St Francis Bay	",
    "Stirling	",
    "Sunridge Village	",
    "The Acres	",
    "Uitenhage 	",
    "Vincent	",
    "Walmer",
    "Willow Roa",
  ],
  "Free State": [
    "Bloemfontein Langenhoven Park",
    "Bloemfontein Waterfront",
    "Fleurdal",
    "Frankfort",
    "Goldfields",
    "Heilbron",
    "Kroonnstad",
    "Langenhoven Park",
    "Northridge Mall",
    "Parys",
    "Preller (Bloemfontein)",
    "Welko",
  ],
  Gauteng: [
    "Alberton",
    "Allens Nek",
    "Arcon Park",
    "Atterbury",
    "Balfour Park",
    "Bassonia",
    "Bedford Centre",
    "Bedfordview",
    "Bedworth Park",
    "Benoni Lakeside",
    "Birchleigh",
    "Blue Hills",
    "Blueberry",
    "Boksburg Centre Point",
    "Boskruin Village",
    "Braamfontein",
    "Bracken City",
    "Brakpan",
    "Bram Fischer",
    "Brentwood Park",
    "Brits",
    "Brixton",
    "Broadacres",
    "Brooklyn",
    "Brooklyn SAQA",
    "Bruma",
    "Bryanston",
    "Castle Walk",
    "Cornwall View",
    "Cosmo City",
    "Craighall",
    "Cresta",
    "Crowthorne",
    "Dainfern Square",
    "Derdepark",
    "Doornpoort",
    "DoringKloof",
    "Dowerglen",
    "East Lynne",
    "East Rand Corner",
    "Eastwood Village",
    "Eden Glen",
    "Edenvale",
    "EI Ridge",
    "Elarduspark",
    "Eldo Square",
    "Eldoraigne",
    "Epsom Downs",
    "Equestria",
    "Esselen Street",
    "Faerie Glen",
    "Fairlands",
    "Farrarmere",
    "Feather Square",
    "Featherbrooke Village",
    "Fendale on Republic",
    "Ferndale Village",
    "Florida",
    "Fontainbleau",
    "Fordsburg",
    "Forest Hill",
    "Fourways Crossing",
    "Fourways Mall",
    "Gallo Manor",
    "Gezina",
    "Gift Acres",
    "Glen Marias",
    "Glenfair",
    "Glenhazel",
    "Greenside",
    "Grey Owl",
    "Groenkloof",
    "Hartbeespoort Dam",
    "Hatfield ",
    "Heidelberg",
    "Heidelberg Mall",
    "Hennopspark",
    "Hyde Park Corner",
    "Irene",
    "Jackal Creek Corner",
    "Jewel City",
    "JHB CBD Fox Street",
    "Kempton Gate",
    "Kempton Park",
    "Killerney Mall",
    "Kolonnade",
    "Krugersdorp",
    "Kya Sand",
    "Kyalami",
    "Lambton",
    "Laudium",
    "Lemon Tree",
    "Lenasia",
    "Linden",
    "Linksfield (Neighborhood Square)",
    "Loftus Park",
    "Lynnwood Hillcrest",
    "Lynnwood Lane",
    "Lyttelton",
    "Mall of the South",
    "Mayville",
    "Meadowdale",
    "Melrose Arch",
    "Meville",
    "Menlo Park",
    "Menlyn (Waterkloof Glen)",
    "Meyersdale",
    "Meyerton",
    "Midrand",
    "Midstream",
    "Montana",
    "Monument Park",
    "Mooikloof",
    "Morningside",
    "Mulbarton",
    "N1 Big Bird",
    "Newtown Junction",
    "Nicolway",
    "Nigel",
    "Noordheuwel",
    "Northcliff",
    "Northcliff Square",
    "Northgate",
    "Northworld",
    "Norwood",
    "Oakfields",
    "Oaklands",
    "Old JHB Road (Lifestyle Centre)",
    "Olivdale",
    "Olivewood",
    "Ontdekkers",
    "Ormonde",
    "Palm Court",
    "Parkhurst",
    "Parkrand",
    "Parktown",
    "Parkview",
    "Paulshof",
    "Pierre Van Ryneveld",
    "PostNet-Linden",
    "PostNet Watermeyer Park",
    "Pretoria CBD",
    "Pretoria North (Nina Park)",
    "Pretoria The Grove",
    "Pretoria West",
    "Princess Crossing",
    "Queens Corner",
    "Radiokop",
    "Rand Steam",
    "Randburg CBD",
    "Randfontein",
    "Rant En Dal",
    "Raslouw",
    "Ridgeway Gardens",
    "Rivonia",
    "Rosebank",
    "Ruimsig Centre",
    "Rustenburg Square",
    "Rynfield Terrace",
    "Sandton City",
    "Sasolburg",
    "Saxonworld",
    "Serengeti",
    "Silver Lakes",
    "Silverton",
    "Sinoville",
    "South East Boulevard",
    "Southdale",
    "Southdowns",
    "Springs Mall",
    "Springsgate",
    "Station Square",
    "Stoneridge",
    "Strubens Valley",
    "Summerfields",
    "Sunninghill",
    "Sunninghill Chilli Lane",
    "Sunward Lifestyle Centre",
    "The Glen",
    "The Islands",
    "The Reef",
    "Three Rivers",
    "Town Midrand",
    "Trinity Village",
    "Vaal Mall",
    "Vaal Park",
    "Van Der Hoff",
    "Verwoerd Park",
    "Victory Park",
    "Vorna Valley",
    "Walkerville",
    "Waterfall Corner",
    "Waterfall Mall",
    "Waterfall Ridge",
    "Waterkloof Ridge",
    "Waverley",
    "Westgate",
    "Westwood Village ",
    "Wonderboom Junction",
    "Woodmea",
  ],
  "KwaZulu-Natal": [
    "Amanzimtoti",
    "Ballito",
    "Berea Road",
    "Bluff",
    "Chatsworth",
    "Cowey Park",
    "Dundee",
    "Durban Club Chambers (DBN CBD)",
    "Empangeni",
    "Estcourt",
    "Glen Ashley",
    "Glenwood",
    "Hillcrest",
    "Hilton",
    "Howick ",
    "Kloof",
    "Knowles Centre",
    "La Lucia",
    "Linkhills Waterfall",
    "Margate",
    "Matatiele",
    "Mount Edgecombe",
    "Mtubatuba",
    "Newcastle Mall",
    "Nottingham Road",
    "Pavilion",
    "Pietermaritzburg Athlone",
    "Pietermaritzburg Hayfields",
    "Pietermaritzburg ",
    "Pinetown",
    "Pongola",
    "Port Edward",
    "Port Shepstone",
    "Queensburg",
    "Richards Bay",
    "Rolyats",
    "Salt Rock",
    "Samia",
    "Scottburg",
    "Shelly Beach",
    "South Beach",
    "Sparks Road",
    "Stanger",
    "Umdloti",
    "Umhlanga Ridge",
    "Umhlanga Village",
    "Verulam",
    "Victoria Square",
    "Vryheid",
    "Westville",
    "Westville North",
    "Windermere",
    "Winterton",
  ],
  Limpopo: [
    "Flora Park",
    "Giyani",
    "Hampton Court",
    "Ivy Park",
    "Lephalale (Ellisras)",
    "Library Gardens",
    "Maake Plaza",
    "Makhado (Louis Trichard)",
    "Mall of the North",
    "Modjadji Plaza",
    "Mokopane",
    "Musina",
    "Naboomspruit",
    "Paledi Mall",
    "Petrostop Polokwane",
    "Polokwane North",
    "Thavhani Mall",
    "Thohoyandou",
  ],
  Mpumalanga: [
    "Ben Fleur",
    "Bronkhorstspruit",
    "Burgersfort",
    "Delmas",
    "Ermelo Express",
    "Hazyview",
    "Highveld Mall",
    "Hoedspruit",
    "Kriel",
    "Lydenburg",
    "Malalane",
    "Middelburg Aerorand",
    "Nelspruit Crossing",
    "Nelspruit The Grove",
    "Phalaborwa",
    "Piet Retief",
    "Steelpoort",
    "Trichardt",
    "Whiteriver Crossing",
    "Witbank",
  ],
  "Northern Cape": [
    "Hartswater",
    "Kathu Heritage Square",
    "Kathu Village Mall",
    "Kimberley",
    "Kuruman",
    "Postmasburg",
    "Upington",
    "Vredendal",
    "Vryburg",
  ],
  "North West": [
    "Bela Bela",
    "Hammanskraal",
    "Marbel Hall",
    "Mooinooi",
    "PostNet Groblersdal",
    "Thabazimbi",
  ],
  "Western Cape": [
    "Bellville Edward Street",
    "Brackenfell",
    "Bredasdorp",
    "Burgundry Estate",
    "Canal Walk",
    "Cape Gate (New)",
    "Cape Town Cape Quarter",
    "Cape Town Gardens",
    "Cape Town Metropolitan",
    "Century City",
    "Claremount",
    "Constantia",
    "CPT Lakeside",
    "Durbansville",
    "Fish Hoek",
    "Franschhoek",
    "Gansbaai",
    "Gardens Shopping Centre",
    "George",
    "Gordon’s Bay",
    "Groot Brak",
    "Haasendal",
    "Hartenbos",
    "Heather Park",
    "Hermanus",
    "Hermanus Sandbaai",
    "High Str Tygervalley",
    "Hout Bay",
    "Kenilworth",
    "Knysna",
    "Kiulsriver",
    "Langebaan",
    "Malmesbury",
    "Meadowridge",
    "Melkbosstrand",
    "Milnerton",
    "Mossel Bay",
    "N1 Value Centre",
    "Observatory",
    "Oudtshoorn",
    "Paarl",
    "Paddocks",
    "Parow",
    "Pinelands",
    "Plattekloof",
    "Plattner Blvd",
    "Plattenberg Bay",
    "Plumstead",
    "Riversdal",
    "Robertson",
    "Rondebosch",
    "Sedgefield",
    "Somerset Mall",
    "Somerset West",
    "Steenberg Village",
    "Stellenbosch Eikestad",
    "Stilbaai",
    "Strand",
    "Sun Valley",
    "Table View Flamingo Sqaure",
    "Table View Parklands",
    "Table View Sandown",
    "Three Anchor Bay",
    "Tokai",
    "V & A Waterfront",
    "West Coast Village ",
    "Woodstock ",
    "Worcester",
  ],
};

export const brandnumbers = [
  "& Other Stories",
  "@home",
  "1 Madison",
  "1 Oak",
  "1. State1.",
  "4.3. Story by Line Up",
  "10 Crosby",
  "Derek Lam",
  "10.Deep",
  "100% Pure",
  "1017 ALYX 9SM",
  "11 By Boris Bidjan Saberi",
  "111 SKIN",
  "1154 Lill Studio",
  "11th Street",
  "12 Pm By Mon Ami",
  "12th Tribe",
  "143 Girl",
  "143rd Style Haus",
  "14th & Union",
  "17 Stories",
  "17 Sundays",
  "17/21 Exclusive Denim",
  "17 Basics",
  "1822 Denim",
  "19 Cooper",
  "1901",
  "191 Unlimited",
  "1928",
  "1989 Place",
  "1D",
  "1st Kiss2 a T Boutique",
  "2 Chic",
  "2 Cute",
  "2 Lips Too",
  "2.1 Denim",
  "212 Collection",
  "21 Men",
  "22 DECEMBER",
  "222 Fifth",
  "24/7 Comfort Apparel",
  "24th & Ocean",
  "26 International",
  "27 Miles Malibu",
  "2B Bebe",
  "2B. Rych2bamboo",
  "2Chillies",
  "2kuhl",
  "2Tee Couture",
  "2xist",
  "2xu",
  "3 Royal Dazzy",
  "3 Sisters",
  "3.1 Phillip Lim",
  "3.1 Phillip Lim for Target",
  "31 Bits",
  "32 Degrees",
  "337 Brand",
  "36 Point 5",
];

export const brandA = [
  "A Classy Chic Boutique",
  "A Fine Mess",
  "A Girl Thing",
  "A Is For Audrey",
  "A Knitch Above",
  "A Mermaid's Epiphany",
  "A More Fabulous You",
  "A New Day",
  "A Pea in the Pod",
  "A Penny Short",
  "A'gaci",
  "A'Reve",
  "A+ Ellen",
  "A. Byer",
  "A. Giannetti",
  "A. Marinelli",
  "A. Testoni",
  "A.J. Bari",
  "A.J. Morgan",
  "A.L.C.",
  "A.N.A",
  "A.P.C.",
  "A.P.N.Y.",
  "A.X.N.Y. ",
  "American Exchange",
  "A/X Armani Exchange",
  "A2 By Aerosoles",
  "A3 Design",
  "A:Glow",
  "Aa Studio",
  "Aakaa",
  "Aaron Ashe",
  "Aaron Chang",
  "AB Studio",
  "Abaete",
  "Abas",
  "Abbeline",
  "Abbey Dawn",
  "Abbie's Anchor",
  "Abbot Main",
  "ABBY & GRACE",
  "ABC Carpet & Home",
  "Abella",
  "Abercrombie",
  "Abercrombie  & Fitch",
  "Abercrombie kids",
  "Abi Ferrin",
  "Able",
  "Abode",
  "Abound",
  "About A Girl",
  "Abrams Books",
  "Abrand",
  "ABS Allen Schwartz",
  "Abs Platinum",
  "Absolute Angel",
  "Absolutely",
  "Absolutely Creative Worldwide",
  "Absolutely Famous",
];

export const brandB = [
  " B Brian Atwood",
  "B Darlin",
  "B Home Interiors",
  "B Jewel",
  "B New York",
  "B&B Italia",
  "B-Long Boutique",
  "B-Low the Belt",
  "B. Makowsky",
  "B. Smart",
  "B. Swim",
  "B.L.E.U.",
  "B.o.c.",
  "B.O.D by Rachael Finch",
  "Ba&sh",
  "Babaton",
  "Babette Clothing",
  "Babe & Tess",
  "Babiators",
  "BabiesRUs",
  "Baby Aspen",
  "BaBy BanZ",
  "Baby Be Mine",
  "Baby Boden",
  "Baby CZ",
  "Baby Deer",
  "Baby Essentials",
  "Baby Fanatic",
  "Baby Gear",
  "Baby Graziella",
  "Baby Leg",
  "Baby Nay",
  "Baby Milo",
  "Baby Phat",
  "Baby Soy",
  "Baby Starters",
  "Baby Steps",
  "BabyBriefcase",
  "Babycottons",
  "BaByliss",
  "Babymel",
  "Baccarat",
  "Baccini",
  "Bacco Bucci",
  "Bacon Actvie",
  "Bacon Bikini",
  "Bachrach",
  "Back Beat Co.",
  "Back2Basics",
  "Back2Basics Boutique",
  "Bad Habit",
  "Badgley Mischka",
  "Badgley Mischka Home",
  "Baffin",
  "Bagatelle",
  "Bagatiba",
  "Baggallini",
  "BAGGU",
  "Bagutta",
];
export const brandC = [
  "C de C",
  "C Label",
  "C&C California",
  "C'est Ca New York",
  "C. Jere",
  "C. Luce",
  "C. Wonder",
  "C.C",
  "C.C. Boutique",
  "C.I. Castro",
  "C.P. Company",
  "C/MEO Collective",
  "C2 by Calibrate",
  "C9 by Champion",
  "Cabana Life",
  "Cabela's",
  "Cabernet",
  "Cabi",
  "Cabin Creek",
  "Cable & Gauge",
  "Caboodles",
  "Cabrini",
  "Cache",
  "Cache Coeur",
  "Cachet",
  "Cacique",
  "CactusCactus Jack by Travis Scott",
  "Cake",
  "CALE",
  "Caleb Siemon",
  "Cali Gal",
  "CALIA by Carrie Underwood",
  "Calibre",
  "California Costumes",
  "California Waves",
  "Call It Spring",
  "Callahan",
  "Callaway",
  "Callie Lives",
  "Callisto",
  "Callisto Home",
  "Calpak",
  "Calphalon",
  "Cals",
  "Calvin Klein",
  "Calvin Klein Collection",
  "Calvin Klein Jeans",
  "Calvin Klein Underwear",
  "Calypso St. Barth",
  "Cambridge Dry Goods",
  "Camelbak",
  "Cameo",
  "Cami NYC",
  "Camilla",
  "Camilla & marc",
  "Camille La Vie",
  "Camo",
  "Campana Brothers",
  "Camper",
];
export const brandD = [
  "D Celli",
  "D&G",
  "D&Y",
  "D'Or Skincare",
  "D-Link",
  "D. jeans",
  "D. Stevens",
  "D.Green Designs",
  "D.S. & Durga",
  "D.V. Kap Home",
  "Da-Nang",
  "Dabney Lee",
  "Dagne Dover",
  "Daily Paper",
  "Daily Ritual",
  "Dainty Hooligan",
  "Dainty Jewells",
  "Daiso",
  "Daisy",
  "Daisy Corsets",
  "Daisy Fuentes",
  "Daisy Lane",
  "Daisys Boutique",
  "Dakine",
  "Dakini",
  "Dale of Norway",
  "Dale Tiffany",
  "Dalia",
  "Dalia Collection",
  "Damir Doma",
  "Damo",
  "Damon Sky",
  "Dan Post",
  "Dana Buchman",
  "Dana Kay",
  "Danalli",
  "Danbury Mint",
  "Dance & Marvel",
  "Dance Class",
  "Dancing Queen",
  "Dangerfield",
  "Dani Rogue",
  "Daniel Cremieux",
  "Daniel Hechter",
  "Daniel K",
  "Daniel Rainn",
  "Daniel Wellington",
  "Daniele Alessandrini",
  "Daniele Fiesoli",
  "DANIELLE BERNSTEIN",
  "Danielle Guizio",
  "Danielle Nicole",
  "Danier",
  "Danner",
  "DANNIJO",
  "Danny & Nicole",
  "Danny Kaplan",
  "Dansk",
  "Danskin",
];
export const brandE = [
  "E-Land Kids",
  "E.Kammeyer Accessories",
  "Eachine",
  "Earl Jeans",
  "Earnest Sewn",
  "Earth",
  "Earth Art hand crafted artisan",
  "Earth Origins",
  "Earth Spirit",
  "Earth Yoga",
  "EARTHBOUND",
  "Earthies",
  "Earthlust",
  "EARTHADDICT",
  "Earthchild",
  "Easel",
  "East 5th",
  "Eastern Mountain Sports",
  "Eastland",
  "Easton",
  "Eastpak",
  "Easy Spirit",
  "Easy street",
  "EB Denim",
  "Eberjey",
  "Ebern Designs",
  "Ecco",
  "Eccolo",
  "Echo",
  "Echo Design",
  "ECHT",
  "ECI",
  "Eckhaus Latta",
  "Ecko Unlimited",
  "Eclectic Treasures",
  "Eclipse",
  "Ecote",
  "Ecotools",
  "Ecru",
  "Ed Hardy",
  "Edgars",
  "Eddie Bauer",
  "Eddie Borgo",
  "Eden & Olivia",
  "Edge",
  "Edgehill Collection",
  "Edith Domen",
  "Edith Heath",
  "Editions Milano",
  "Edox",
  "EDUN",
  "Edward Wormley",
  "Edwin",
  "Eero",
  "Eero Saarinen",
  "Effy",
  "Egara",
  "Egg Collective",
  "EGO",
];
export const brandF = [
  "F&F",
  "FA",
  "fab'rik",
  "Faberge",
  "Fabiani",
  "Fabindia",
  "Fabkids",
  "Fabletics",
  "Fabrizio Del Carlo",
  "Fabulous Furs",
  "Fabutiq",
  "Faconnable",
  "Factorie",
  "Factory Five Apparel",
  "Faded Glory",
  "Faherty",
  "Fahrenheit",
  "Fairlygirly",
  "Fairway & Greene",
  "Faith & Nate Boutique",
  "Faith and Joy",
  "Faith Connexion",
  "Faithfull the Brand",
  "Falke",
  "Falls Creek",
  "Fame and Partners",
  "Famous Stars & Straps",
  "Fanatics",
  "Fancyinn",
  "FANG",
  "Fantasie",
  "Fantastic Fawn",
  "FAO Schwarz",
  "Farah",
  "Farah Jewelry",
  "Farai London",
  "Farberware",
  "FARM Rio",
  "Farmacy",
  "Farmasi",
  "FARSALI",
  "Fashion BohoLoco",
  "Fashion Bug",
  "Fashion Corner LA",
  "Fashion Forms",
  "Fashion Jewelry",
  "Fashion Made Right Boutique",
  "Fashion Magazine",
  "Fashion Nova",
  "Fashion Poshers",
  "Fashion to Figure",
  "Fashion Union",
  "Fashionomics",
  "Fat Face",
  "Fate",
  "Faviana",
  "FAVLUX",
  "Favorite Characters",
  "Fawn Design",
];

export const brandG = [
  "G by Giuliana",
  "G by Guess",
  "G Home Collection",
  "G-Shock",
  "G-Star",
  "G. Debrekht",
  "G.H. Bass & Co.",
  "G.I.L.I.",
  "Gabby Isabella",
  "Gabby Skye",
  "Gabor",
  "Gabriella Crespi",
  "Gabriella Rocha",
  "Gabrielle de Vecchi",
  "Gaetano Pesce",
  "GAIAM",
  "Gal Meets Glam",
  "Galaxy By Harvic",
  "Galaxy Gold Products",
  "Gallery",
  "Gallery_of_gems",
  "Galliano",
  "Gallucci",
  "Game Time",
  "Ganesh",
  "Ganni",
  "Gant",
  "GANZ",
  "GAP",
  "GAP Factory",
  "Garage",
  "Garanimals",
  "Garmin",
  "Garnet Hill",
  "Garnier",
  "Garrett Leight",
  "Garrie B",
  "Gartner Studios",
  "GASOLINE GLAMOUR",
  "Gatco",
  "Gaudi'",
  "Gauge81",
  "Gauntlett Cheng",
  "Gayla Bentley",
  "Gaze",
  "Gazzarrini",
  "GB girls",
  "GBG Los Angeles",
  "GBX",
  "Gear For Sports",
  "Gemma Finch Boutique",
  "Gemmy",
  "General Pants Co.",
  "Generation Clay",
  "Generation Love",
  "GENERATION Y",
  "GENERRA",
  "Genetic Denim",
  "Geneva",
];
export const brandH = [
  "H by Bordeaux",
  "H by Halston",
  "H By Hudson",
  "H&M",
  "H.i.p.",
  "H:ours",
  "Haani",
  "Haband",
  "Habitat",
  "Habitual",
  "Hachette",
  "Hackett",
  "Haddad",
  "Haerfest",
  "Haffke",
  "Haflinger",
  "Haggar",
  "Haia",
  "Haia Accessories",
  "Haider Ackermann",
  "Haiku",
  "Hailey Logan",
  "Halabaloo",
  "HALARA",
  "Hale Bob",
  "Half It",
  "Half United",
  "Hallmark",
  "Halo",
  "Halo Innovations",
  "Halogen",
  "Halston Heritage",
  "Hamilton",
  "Hamilton Beach",
  "Hammitt",
  "Hand Crafted",
  "Hanes",
  "Hang Ten",
  "Hanifa",
  "Hanky Panky",
  "Hanna Andersson",
  "Hannah",
  "Hannah Banana",
  "Hannah Beury",
  "Hannah Rose",
  "Hanns-Peter Krafft",
  "Hanro",
  "Hansen & Gratel",
  "HAODUOYI",
  "Hapari",
  "Happening in the present",
  "Happy Mama Boutique",
  "Happy Planner",
  "Happy Socks",
  "Harajuku Lovers",
  "Harbor Bay",
  "Hard Candy",
  "Hard Rock Café",
  "Hard Tail",
];
export const brandI = [
  "I Heart Ronson",
  "I jeans by Buffalo",
  "I Love Gorgeous",
  "I play.",
  "I.AM.GIA",
  "I.N. San Francisco",
  "I.N. Studio",
  "Ibex",
  "Ice",
  "Ice Iceberg",
  "Iceberg",
  "Icebreaker",
  "Icecream",
  "Ichendorf",
  "Icing",
  "ICON",
  "Iconic Legend",
  "ICONIC London",
  "Idea Piu",
  "Ideology",
  "Identity",
  "IEdesigned",
  "If it were me",
  "IFashionBox",
  "Igloo",
  "Igor",
  "Igor Dobranic",
  "iHeartRaves",
  "iHomeI",
  "ittala",
  "Ike Behar",
  "IKEA",
  "Ikks",
  "Il Buco Vita",
  "Il Gufo",
  "IL MAKIAGE",
  "Ile-Ila",
  "ILIA",
  "ILLA ILLA",
  "Illamasqua",
  "Illesteva",
  "ILOVEMASKS",
  "Ilse Crawford",
  "Ilse Jacobsen",
  "Ily Couture",
  "Imaginary voyage",
  "IMAN",
  "Imanimo",
  "Imoga",
  "Imoshion",
  "Imperial Motion",
  "Imperial Porcelain",
  "Imperial Star",
  "Impo",
  "impress",
  "Impressions",
  "Imps & Elfs",
  "In Bloom",
  "In2",
];
export const brandJ = [
  "J Brand",
  "J for Justify",
  "J KaraJ-41",
  "J. America",
  "J. Crew",
  "J. Crew Factory",
  "J. Garcia",
  "J. Jill",
  "J. Khaki",
  "J. Lindeberg",
  "J. McLaughlin",
  "J. Mendel",
  "J. Peterman",
  "J.B.S.",
  "J.Elizabeth",
  "J.Lindeberg",
  "J.M.W",
  "J.O.A.",
  "J.Renee",
  "J.W. Anderson",
  "J.W. Tabacchi",
  "J/SLIDES",
  "Jaanuu",
  "Jaase",
  "Jac + Jack",
  "Jac Vanek",
  "Jacadi",
  "Jachs",
  "JACHS Girlfriend",
  "Jack and Jones",
  "Jack by BB Dakota",
  "Jack Daniels",
  "Jack London",
  "Jack Mason Brand",
  "Jack Rogers",
  "Jack Spade",
  "Jack Wills",
  "Jack Wolfskin",
  "JACKSON",
  "Jaclyn Smith",
  "Jacob",
  "Jacob Cohen",
  "Jacqueline Ferrar",
  "Jacquemus",
  "Jacques Adnet",
  "Jacques Lemans",
  "Jacques Moret",
  "Jacqui E",
  "Jade",
  "Jade Swim",
  "Jadelynn Brooke",
  "JAFRA",
  "Jag Jeans",
  "Jagermeister",
  "Jaggad",
  "Jaggy",
  "Jaime Hayon",
  "Jaipur",
  "Jamaica Jaxx",
];
export const brandK = [
  "K&K Interiors",
  "K-DEER",
  "K-Swiss",
  "K-Way",
  "K.H. Wurtz",
  "K/Lab",
  "Kaachi & Co.",
  "Kaaiga",
  "Kaanas",
  "Kaari Blue",
  "Kaeli Smith",
  "Kaii",
  "Kaileigh",
  "Kaisely",
  "Kaitlyn",
  "Kaj Franck",
  "Kaktus",
  "Kala Vella",
  "Kali",
  "Kalli Collection",
  "Kalon Studios",
  "Kameakay",
  "Kamik",
  "KanCan",
  "KangaROOS",
  "Kangol",
  "Kangra Cashmere",
  "Kanna",
  "Kanu Surf",
  "Kanz",
  "KAOHS",
  "Kapital",
  "Kaplan",
  "Kappa",
  "Kara and Kate",
  "Kardashian Kids",
  "Kardashian Kollection",
  "Kardo",
  "Karen Didion Originals",
  "Karen Kane",
  "Karen Millen",
  "Karen Neuburger",
  "Karen Scott",
  "Karen Walker",
  "Karen Zambos",
  "Karim Rashid",
  "Karin stevens",
  "Karina Grimaldi",
  "Karis' Kloset",
  "Karl Lagerfeld",
  "Karl Springer",
  "Karlie",
  "Karma",
  "Kartell",
  "Kasper",
  "Kassatex",
  "Kat Von D",
  "Kate & Mallory",
  "Kate and Laurel",
];
export const brandL = [
  "L&B",
  "L'Academie",
  "L'AGENCE",
  "L'Amour",
  "L'Artisan Parfumeur",
  "L'ATISTE",
  "L'Objet",
  "L'OCCITANE",
  "L'Oreal",
  "L'ovedbaby",
  "L'URV",
  "L'Wren Scott",
  "L'Wren Scott at Banana Republic",
  "L*space",
  "L.A. Blues",
  "L.A. Colors",
  "L.A. Gear",
  "L.A. idol",
  "L.A. Shoes",
  "L.A.M.B.",
  "L.B. Evans",
  "L.L. Bean",
  "L.O.L. Surprise!",
  "L.O.L. Vintage",
  "L8teR",
  "La Belle",
  "La Blanca",
  "La Boheme",
  "La CANADIENNE",
  "La Chance",
  "LA DoubleJ",
  "La DoubleJ Housewives",
  "La Femme",
  "La Fiorentina",
  "La Gallina Matta",
  "LA Girl",
  "La Hearts",
  "La Kaiser",
  "La Leche League International",
  "LA made",
  "La Mer",
  "La Mouette",
  "La Perla",
  "La Perla Home",
  "LA Pop Art",
  "La Prairie",
  "La Regale",
  "La SENZA",
  "La Sportiva",
  "La Stampa",
  "La Stupenderia",
  "La Vie En Rose",
  "Lab. Pal Zileri",
  "LAB40",
  "Label Ritukumar",
  "Laboratorio Pesaro",
  "LACAUSA",
  "Lacey Ryan",
  "Lack Of Color",
];
export const brandM = [
  "M by Missoni",
  "M&M'S",
  "M. Gemi",
  "M. Rena",
  "M.Grifoni Denim",
  "M.J. Bale",
  "M.STUDIO",
  "Maaji",
  "Maapilim",
  "Mabella Chic",
  "Mac & jac",
  "MAC Cosmetics",
  "Mac Duggal",
  "Macbeth Collection by Margaret Josephs",
  "Macchia J",
  "Macgraw",
  "MACH & MACH",
  "MaCherie",
  "Machine",
  "Mack Weldon",
  "Mackage",
  "Mackenzie Mode",
  "MacKenzie-Childs",
  "Macpac",
  "Macy's",
  "MAD Engine",
  "Mad Love",
  "Madame Alexander",
  "Madden Girl",
  "Madderson London",
  "Made By Design",
  "Made for life",
  "Made Kids",
  "MADELEINE THOMPSON",
  "Madeline",
  "Madeline Stuart",
  "Madewell",
  "Madhappy",
  "Madison",
  "Madison & Berkeley",
  "Madison james",
  "Madison leigh",
  "Madison Park",
  "Madison Studio",
  "Madison West",
  "Mads Norgaard",
  "Madson Discount",
  "Maestrami",
  "Maeve",
  "Magaschoni",
  "Magellan Outdoors",
  "Magen's Fairytale Creations",
  "Magenta",
  "Maggie & Zoe",
  "Maggie Barnes",
  "Maggie Louise",
  "Maggie Sottero",
  "Maggy London",
  "Magic",
];
export const brandN = [
  "N.d.c.",
  "N.Y.L.A.",
  "n:PHILANTHROPY",
  "Na Hoku",
  "NAADAM",
  "Naartjie",
  "Nadia Rima",
  "Nadri",
  "Naeem Khan",
  "NAF NAF",
  "Naghedi",
  "Nagnata",
  "Nahui Ollin",
  "NAIF",
  "NAILA",
  "Nails at Play",
  "Nais",
  "NAK",
  "Nakamol",
  "Naked & Famous Denim",
  "Naked cosmetics",
  "Naked Feet",
  "Naked Truth",
  "Naked wardrobe",
  "Naked Zebra",
  "Naked Cashmere",
  "Naketano",
  "Nakimuli",
  "Nambe",
  "Nameless",
  "NaNa",
  "NANA Judy",
  "Nanaloafers",
  "NanaMacs",
  "Nancy Ganz",
  "Nancy Gonzalez",
  "Nancy Rose Performance",
  "Nando Muzi",
  "Nanette Baby",
  "Nanette Lepore",
  "Nanette Lepore for Keds",
  "Nanis Italian Jewels",
  "Nanna Ditzel",
  "Nannette",
  "Nannini",
  "Nanny Still",
  "Nano",
  "Nanushka",
  "Naot",
  "Napapijri",
  "Napier",
  "Napoleon Perdis",
  "Naracamicie",
  "Narciso Rodriguez",
  "Nardos Imam",
  "Narianna",
  "NARS",
  "Nascar",
  "Nashelle",
];

export const brandO = [
  "O'2nd",
  "O'Neill",
  "OAK",
  "OAK + FORT",
  "Oakley",
  "Oamc",
  "OAS",
  "OASAP",
  "Oasis",
  "Obagi",
  "Obakki",
  "Obermeyer",
  "Obey",
  "Objects of Desire",
  "Obsession Rules",
  "Obsessions Couture",
  "Obsessive Compulsive Cosmetics",
  "Obus",
  "OC by Oleg Cassini",
  "OC Style",
  "Occasion",
  "Occasion",
  "Ocean Current",
  "Ocean Dreams",
  "Ocean Drive",
  "Ocean Fashion",
  "Oceanaut",
  "Ocra",
  "October Love",
  "Oculus",
  "Oday Shakar",
  "Odd Future",
  "Odd Molly",
  "ODDY",
  "Odille",
  "Oeuf",
  "Of Two Minds",
  "Off-White",
  "Offi",
  "Officine Creative",
  "Officine Generale",
  "Offspring",
  "OFRA",
  "OGIO",
  "Oh",
  "Oh Baby by Motherhood",
  "Oh Deer!",
  "Oh Minky",
  "Oh My Gauze!",
  "OH MY JULIAN INC.",
  "Oh My Love",
  "Oh Polly",
  "Oh! Mamma",
  "Oh, it's Fayth",
  "Ohii",
  "Ohne Titel",
  "Oilily",
  "Oilo Studio",
  "OiOi Austral",
];
export const brandP = [
  "P L Designs and More",
  "P&Y Denim",
  "P'tula",
  "P.E Nation",
  "P.F. Flyers",
  "P.J. Salvage",
  "P.R.I.M.A. Glitz by Kari Chang",
  "P448",
  "Paavo Tynell",
  "Pablo Pardo",
  "Pablosky Kids",
  "Pacific Connections",
  "Pacific Sunwear",
  "Pacific Trail",
  "Pacifica",
  "Packed Party",
  "Paco Gil",
  "Paco Rabanne",
  "Pacsafe",
  "PacSun",
  "Pact",
  "PAIGE",
  "Painted threads",
  "Paisley Sky",
  "Pajar",
  "Pakaloha",
  "Pal Zileri",
  "PALACE",
  "Palecek",
  "Pali Hawaii",
  "Palladium",
  "Palm Angels",
  "Palm Beach Boutique",
  "Palm Beach Sandals",
  "Paloma Barcelo",
  "Paloma Blanca",
  "Paloma Picasso",
  "Paloma Wool",
  "Pam & Gela",
  "Pamela Dennis",
  "Pamela Love",
  "Pamela McCoy",
  "Pamella Roland",
  "Pampaloni Silver",
  "Pampered Chef",
  "Pampili",
  "Pampolina",
  "Panacea Cache",
  "Panache",
  "Panache Lingerie",
  "Panama Jack",
  "Panasonic",
  "Pandora",
  "Panerai",
  "Pangaia",
  "Panhandle Slim",
  "Panini",
  "Paniz",
  "Panoply",
];
export const brandQ = [
  "Q by Pasquale",
  "Q-T Intimates",
  "Q2",
  "QED London",
  "Qi",
  "QMack",
  "Qristyl Frazier Designs",
  "Quacker Factory",
  "Quay Australia",
  "Que",
  "Quechua",
  "Queen Bee Maternity",
  "Quiltex",
  "Quinn",
  "Quoddy",
  "Qupid",
  "QVC",
];
export const brandR = [
  "R & M Richards",
  "R 13",
  "R&K",
  "R&M Richards",
  "R&Y Augousti",
  "R+Co",
  "R.J. Graziano",
  "R.M. Williams",
  "R13",
  "R2",
  "Rabbit Rabbit Rabbit",
  "Rabbit Skins",
  "Rachael & Chloe",
  "Rachael Ray",
  "Rachel",
  "Rachel Allan",
  "Rachel Antonoff",
  "Rachel Art Jewelry",
  "Rachel Comey",
  "Rachel Gilbert",
  "Rachel Kate",
  "Rachel Leigh",
  "Rachel Pally",
  "Rachel Pally White Label",
  "Rachel Parcell",
  "RACHEL Rachel Roy",
  "Rachel Reinhardt",
  "Rachel Riley",
  "Rachel Shoes",
  "Rachel Simpson",
  "Rachel Zoe",
  "Rad and Refined",
  "RADLEY LONDON",
  "Radzoli",
  "Rae Dunn",
  "RAEN",
  "Raf Simons",
  "RAFAEL",
  "Rafaella",
  "Rafe",
  "Raffirag & bone",
  "RAGA",
  "RAGAZZA",
  "Ragdoll",
  "Rage",
  "Rags and Couture",
  "Rags to Raches",
  "Rails",
  "Rainbow",
  "Rainbow Club",
  "RAINFOREST",
  "Rains",
  "Raisins",
  "Raj",
  "Raleigh Runway",
  "Rallier",
  "Ralph Lauren",
  "Ralph Lauren Black Label",
  "Ralph Lauren Blue La",
];
export const brandS = [
  "S by Serena",
  "S&D",
  "S&G Apparel Inc.",
  "S'well",
  "S-Twelve",
  "S. the Widow",
  "S.L. Fashions",
  "SAACHI",
  "Saba",
  "Sabika",
  "Sabina",
  "Sabina Musayev",
  "Sabine",
  "Sabo Skirt",
  "Sabora",
  "Saboroma",
  "Sabyasachi",
  "Sacai",
  "Sacha London",
  "SACHI",
  "Sachin + Babi",
  "Sacred Threads",
  "Saddlebred",
  "Sade New York",
  "Sadie & Sage",
  "Safavieh",
  "Saga Furs",
  "Sagesahalie",
  "Saie",
  "Saikai",
  "Sail to Sable",
  "Sailormade",
  "Saint Grace",
  "Saint James",
  "Saint John's Bay Active",
  "Saint Laurent",
  "Saint Louis Crystal",
  "Saint Tropez West",
  "Saison Blanche",
  "Saivana",
  "Saja",
  "Sakkas",
  "Sakroots",
  "Saks Fifth Avenue",
  "Saks Fifth Avenue Black Label",
  "Saks Potts",
  "Sakura",
  "Salinas",
  "Sallie Sahne",
  "Sally LaPointe",
  "Sally Miller",
  "Salomon",
  "Salone Monet",
  "SALONI",
  "Salt City Emporium",
  "Salt Gypsy",
  "Salt Lake Clothing",
  "Salt Life",
  "Salt Swimwear",
];
export const brandT = [
  "T by Alexander Wang",
  "T Party Fashion",
  "T Tahari",
  "T&C Floral Company",
  "T&G",
  "T&J Designs",
  "T-Bags",
  "T-Shirt & Jeans",
  "T-Shirt Addicts",
  "T. Cappelli",
  "T.J.Maxx",
  "T.La",
  "T.M.Lewin",
  "T.U.K",
  "T/o",
  "T2 Love",
  "T3",
  "Tabasco",
  "Tabitha",
  "Tabitha Simmons",
  "Taboo",
  "Tacera",
  "TACH",
  "Tacori",
  "Tadashi Shoji",
  "Tafford",
  "Taft",
  "Tag",
  "TAG Elemental",
  "Tag Heuer",
  "Tag Twenty Two",
  "Taggies",
  "Tagliatore",
  "Tahari",
  "Tahari ASL",
  "Tahari Woman",
  "Tai",
  "Tail",
  "Tailor B. Moss",
  "Tailor New York",
  "Tailorbyrd",
  "TAJ by Sabrina Crippa",
  "Taka",
  "Takara",
  "Take out",
  "Takeshy Kurosawa",
  "Talbots",
  "TALENTLESS",
  "Talitha",
  "Talk of the Walk",
  "TALLIA",
  "Tally Ho",
  "Talula",
  "TALULAH",
  "Tamara Mellon",
  "Tammi Lyn",
  "Tanah Folk",
  "Tangerine",
  "Tangerine NYC",
];
export const brandU = [
  "U Pak N Ship",
  "U-Boat",
  "U-Knit Dresses",
  "U-NI-TY",
  "U.S. Army",
  "U.S. Polo Assn.",
  "U2 Wear Me Out",
  "UA Scrubs",
  "Ubiquiti",
  "UE",
  "UGG",
  "Ugly Christmas Sweater",
  "UjENA",
  "UK2LA",
  "Ulbricht",
  "Ulla Johnson",
  "Ulla Popken",
  "Ulla-Maija",
  "Ulta Beauty",
  "Ultimate Miks",
  "Ultra Dress Collection",
  "Ultra Flirt",
  "Ultra Pink",
  "Ulu",
  "Ulysse Nardin",
  "Umberto Raffini",
  "Umbra",
  "Umbro",
  "Umgee",
  "Umi",
  "Umit Benan",
  "Umuhle",
  "Unbranded",
  "Un Deux Trois",
  "Uncle Frank",
  "Uncommon",
  "Uncommon James",
  "Unconditional",
  "Undefeated",
  "Under Armour",
  "Under One Sky",
  "Under Skies",
  "Under.ligne",
  "Undercover",
  "Undercover Mama",
  "Undergirl",
  "Ungaro Fever",
  "UNIF",
  "Uniform Advantage",
  "Unik",
  "Union Rustic",
  "UNIONBAY",
  "Uniqlo",
  "Uniqlo x KAWS",
  "Unique Bargains",
  "Unique Spectrum",
  "Unique Vintage",
  "Unique Zone",
  "Unisa",
];
export const brandV = [
  "V By Eva",
  "V Cristina",
  "V Rugs & Home",
  "V. FRAAS",
  "V::room",
  "Va et Vien for BHLDN",
  "Va Va Voom",
  "Vacheron Constantin",
  "Vagabond",
  "Vagabond House",
  "Vagarant Traveler",
  "VAHAN",
  "Vaillancourt",
  "Vaio",
  "Vakko",
  "Val Stefani",
  "Valenti Franco",
  "Valentina",
  "Valentina Terra",
  "Valentino",
  "Valentino Garavani",
  "Valentino Orlandi",
  "Valerie Bertinelli",
  "Valerie Stevens",
  "Valette",
  "Valextra",
  "Valfre",
  "Valleau Apparel",
  "Valley Eyewear",
  "Valley Lane",
  "Valleygirl",
  "ValMarie",
  "Valmont",
  "Valve",
  "Van Cleef & Arpels",
  "Van Eli",
  "Van Heusen",
  "Vaneli",
  "Vanessa Bruno",
  "Vanessa Mooney",
  "Vanessa Seward",
  "Vanilla Bay",
  "Vanilla Star",
  "Vanilla Sugar",
  "Vanilla/Beach",
  "Vanity",
  "Vanity Fair",
  "VANNI",
  "Vans",
  "Vantel Pearls",
  "Varga",
  "Varley",
  "Varsavia Viamara",
  "Varsity",
  "Vasque",
  "Vassarette",
  "Vaute Couture",
  "Vava by Joy Han",
  "Vavvoune",
];
export const brandW = [
  "W by Worth",
  "W for Women",
  "W. Kleinberg",
  "W.R.K",
  "W118 by Walter Baker",
  "W3ll People",
  "W5",
  "Wacoal",
  "Wade logan",
  "Wahl",
  "Walborg",
  "Walking Cradles",
  "Wallace Silversmiths",
  "Wallflower",
  "Wallis",
  "Wallpapher",
  "Walls",
  "Walter Baker",
  "Walter Genuin",
  "Walter Hagen",
  "Walter Steiger",
  "Wamsutta",
  "Wanakome",
  "Wanda Nylon",
  "Wander",
  "Wander Beauty",
  "Wander boutique",
  "Wanderlust",
  "Wandler",
  "Want It All",
  "WANT Les Essentiels",
  "Wanted",
  "Warby Parker",
  "Ward Bennett",
  "Warehouse",
  "Warm",
  "Warner Bros.",
  "Warner's",
  "Warp + Weft",
  "Warren Platner",
  "Wasson",
  "Waterford",
  "Waterford Crystal",
  "Waterworks",
  "Wathne",
  "Watters",
  "Wave of Life",
  "Waverly",
  "Waverly Grey",
  "Wax Jean",
  "Waxing Poetic",
  "Way Beyoung",
  "Wayf",
  "Wayfair Basics",
  "Wayne Cooper",
  "Wayuu Tribe",
  "WD.NY",
  "We Over Me",
  "We The Free",
];
export const brandX = [
  "X-appeal",
  "X2",
  "Xacus",
  "Xadoo",
  "Xappeal",
  "Xcite Prom",
  "XCVI",
  "Xenia Boutique",
  "Xerox",
  "Xersion",
  "XFX",
  "Xhilaration",
  "XiRENA",
  "XOXO",
  "Xscape",
  "Xtaren",
  "Xtraordinar",
  "Xtreme Lashes by Jo Mousselli",
  "XTRONS",
  "XYD",
];
// Brands starting with Y
export const brandY = [
  "Y&S Handbags",
  "Y's by Yohji Yamamoto",
  "Y-3",
  "Y&G",
  "Y/Project",
  "Ya Los Angeles",
  "Ya Ya Club",
  "Yag Couture",
  "Yahada",
  "Yak Pak",
  "Yakum",
  "Yala",
  "Yamaha",
  "Yamazaki",
  "Yandy",
  "Yankee Candle",
  "Yansi Fugel",
  "Yanuk",
  "Yashica",
  "YaYa Aflalo",
  "Yazilind",
  "YDE",
  "YDN",
  "Year of Ours",
  "Yeezy",
  "Yelete",
  "Yellow Box",
  "YesStyle",
  "Yesterday People",
  "Yeti",
  "Yigal Azrouel",
  "YINER",
  "YL by Yair",
  "YMI",
  "YNS Generic",
  "Yoana Baraschi",
  "Yochi",
  "Yoek",
  "Yoga Bitch",
  "Yogalicious",
  "Yohji Yamamoto",
  "YOINS",
  "Yoki",
  "Yolanda Couture",
  "Yoli Rapp",
  "Yoly Munoz",
  "Yoobi",
  "Yoon",
  "Yoona",
  "Yosi Samra",
  "Youmita",
  "Young & Reckless",
  "Young Essence",
  "Young Fabulous & Broke",
  "Young Living",
  "Youngland",
  "Younique",
  "Yours Clothing",
  "Youswim",
];

// Brands starting with Z
export const brandZ = [
  "Z Gallerie",
  "Z Spoke by Zac Posen",
  "Z Supply",
  "Z Zegna",
  "Z-Coil",
  "Z. Cavaricci",
  "Zable",
  "Zac & Rachel",
  "Zac Posen",
  "Zac Posen for Target",
  "ZAC Zac Posen",
  "Zachary Prell",
  "Zachary's Smile",
  "Zack & Zoey",
  "Zackali 4 Kids",
  "ZAD",
  "Zadig & Voltaire",
  "Zaful",
  "Zagliani",
  "Zales",
  "Zalo",
  "Zalto Glassware",
  "Zana Di",
  "Zando",
  "Zane",
  "Zanella",
  "Zanerobe",
  "Zang Toi",
  "Zanone",
  "Zanzea",
  "Zara",
  "Zara Terez",
  "Zay",
  "ZCO",
  "Zdazzled",
  "Zeagoo",
  "Zedd plus",
  "ZEF",
  "Zele",
  "Zelie for She",
  "Zella",
  "Zella Girl",
  "ZELOS",
  "Zena",
  "Zenana Outfitters",
  "Zenim",
  "Zenith",
  "Zenleather",
  "Zenobia",
  "Zephyr",
  "Zero + Maria Cornejo",
  "Zero 2 Nine Maternity",
  "Zero2three",
  "ZeroXposur",
  "Zerres",
  "Zestt",
  "Zeta Ville",
  "Zeugari",
  "Zhenzi",
];
